@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom Card */

.bg-noise {
  background-image: url("https://source.unsplash.com/random");
}

/* Navigation bar */

.navigation-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px); /* Frosted glass effect */

  display: flex;
  justify-content: flex-end;
  gap: 5px;
  padding: 10px 10px;
}

.section-content {
  padding-top: 10vh;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#experience {
  padding-top: 12vh;
}

#about {
  padding-top: 12vh;
}

@media (min-width: 768px) {
  #about {
    padding-top: 10vh;
  }
}

@media (min-width: 1024px) {
  #about {
    padding-top: 20vh;
  }
}

.project-section-content {
  padding-top: 12vh;
}

/* Custom Button */

.your-custom-button-class {
  background-color: transparent;
  border: none;
  color: black;
  padding-right: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0 10px;
  padding: 5px 5px;
  cursor: pointer;
  transition: color 0.3s;
  transition:
    color 0.3s,
    transform 0.3s ease-in-out; /* Add transition for transform */
}

.your-custom-button-class-selected {
  text-decoration: underline; /* Adds an underline */
}

.your-custom-button-class:hover {
  color: #555;
  transform: scale(1.05);
}

/* Image animation */
@keyframes emergeFromGround {
  from {
    opacity: 0;
    transform: translateY(
      20px
    ); /* Image starts 20px below its final position */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Image ends at its final position */
  }
}

.emerge-animation {
  animation: emergeFromGround 0.8s ease-out;
}

.nav-text {
  @apply poppins-regular text-sm md:text-base lg:text-lg;
}

/* Header Text */
.header-text {
  @apply bebas-neue-regular text-3xl font-bold md:text-4xl lg:text-5xl xl:text-6xl;
}

/* Subheader Text */
.subheader-text {
  @apply bebas-neue-regular text-2xl md:text-3xl lg:text-4xl;
}

/* Body Text */
.body-text {
  @apply text-base md:text-base lg:text-base;
  @apply poppins-regular;
}

/* Fonts */
.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.experience-container {
  position: relative;
  z-index: 0; /* Lower than the navbar */
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.spline-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.content-container {
  position: relative;
  z-index: 1;
  padding-top: 60px;
}

.frosted-glass {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.primary-color {
  color: #7282ff;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  transition:
    opacity 2s ease,
    visibility 2s ease;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
}

.loading-screen.hidden {
  opacity: 0;
  visibility: hidden;
}
